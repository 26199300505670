import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { IUser } from '@common/models/IUser';
import { Observable, of } from 'rxjs';
import { UserResolver } from '@app/user.resolver';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private apiService: ApiService, private userResolver: UserResolver) {
  }

  getMe(): Observable<IUser> {
    return this.apiService.get(`User/Me`);
  }

  updateMe(user: IUser): Observable<IUser> {
    return this.apiService.post(`User`, user);
  }

  getUserByEmail(email: string): Observable<IUser>
  {
    return this.userResolver.getUserByEmail(email);
  }
}

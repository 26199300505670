import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { IFundFamily } from '@common/models/IFundFamily';
import { Observable, map, of, shareReplay } from 'rxjs';
import { ApiService } from './core/services/api.service';
import { IUser } from '@common/models/IUser';

let users: IUser[] = null;

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<IUser[]> {
  constructor(private apiService: ApiService){}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (users===null)  {
      return this.apiService.get(`User`)
      .pipe(
        map((res: IUser[]) =>  users = res),
        shareReplay(1),
      );
    }
  }

  getUserByEmail(email: string): Observable<IUser>
  {
    return of(users.filter(prop => prop.email.toLowerCase() === email.toLowerCase())[0]);
  }
}

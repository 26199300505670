import { Component, HostListener } from '@angular/core';
import { MonitoringService } from './core/services/logging.service';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

let browserRefresh = false;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'bo-quilt-app';
    subscription: Subscription

    @HostListener('window:beforeunload') refreshBrowser() {
        localStorage.removeItem("systemPropertyCache");
        localStorage.removeItem("metricCodeCache");
    }
    constructor(private monitoringService: MonitoringService) {
    }
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UserProfileService } from '@app/modules/user-profile/services/user-profile.service';
import { CommentsService } from '@app/services/common/comments.service';
import { ApprovalRequestType } from '@common/enums/ApprovalRequestType';
import { InputSource } from '@common/enums/InputSource';
import { NoteType } from "@common/enums/NoteType";
import { IToasterData } from '@common/models/IToasterData';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnChanges {
  comments: any;
  @Input() name ;
  @Input() mnemonic;
  @Input() type ;
  activityType : ApprovalRequestType;
  columns = [
    { name: 'User', field: 'user' },
    { name: 'Source', field: 'source' },
    { name: 'Reason', field: 'value' },
    { name: 'Type', field: 'noteType' },
    { name: 'Created Date', field: 'createdAt' }
  ];
  isNotesTypeVisible: boolean = false;
  notes: string = '';
  user;
  toasterData: IToasterData = {};
  constructor(private commentsService: CommentsService, private userService: UserProfileService) {
   this.userService.getMe().subscribe((res)=>{
     this.user = res
    })
   }

  ngOnChanges(): void {
    if(this.type === 'distribution'){
      this.activityType = ApprovalRequestType.Distribution
    }
    else if(this.type === 'contribution'){
      this.activityType = ApprovalRequestType.CapitalCall
    }
    else if(this.type === 'commitment'){
      this.activityType = ApprovalRequestType.Commitment
    }
    else if(this.type === 'valuation'){
      this.activityType = ApprovalRequestType.Valuation
    }
    this.getComments()
  }
  getComments(){
    if(this.mnemonic){
      this.commentsService.get(this.mnemonic,this.activityType, this.name).subscribe({
        next : (res) =>{
          if(res.length > 0){
            for(let i =0;i<res.length;i++){
              console.log(res[i].user)
              this.userService.getUserByEmail(res[i].user).subscribe({
                next : (user) =>{
                  res[i].name = user.name ? user.name:res[i].email;
                },
                error :(error) =>{}
              })
            }
         
          }
          this.comments = [...res];
        },
        error :(error) =>{}
      })
    }
   
  }

  noteChange(event){

  }
  onSave(){
    const notes ={
      mnemonic: this.mnemonic,
      name: this.name,
      value: this.notes,
      source: InputSource.QUILT,
      activityType: this.activityType,
      user: this.user?.email,
      noteType: NoteType.Other
    }
    this.commentsService.add(notes).subscribe((res)=>{
      this.isNotesTypeVisible = false;
      this.toasterData = {
        status: 'success',
        summary: 'success',
        message: 'Successfully added'
      }
      this.getComments()
    })
  }

}
